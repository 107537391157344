@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Raleway", sans-serif;
  color: #fff;
  font-size: 16px;
  line-height: 30px;
  font-weight: 300;
  position: relative;
  overflow-x: hidden;
}

body:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  visibility: hidden;
  opacity: 0;
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease;
  z-index: 8;
}

body.offcanvas {
  overflow-x: hidden;
}

body.offcanvas:before {
  visibility: visible;
  opacity: 1;
}

body, .unslate_co--site-inner {
  background: #000;
}

::-moz-selection {
  background: #000;
  color: #fff;
}

::selection {
  background: #000;
  color: #fff;
}

a {
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease;
}

a, a:hover {
  text-decoration: none !important;
}

.unslate_co--site-inner {
  margin-bottom: 400px;
  position: relative;
  width: 100%;
  z-index: 1;
}

@media (max-width: 991.98px) {
  .unslate_co--site-inner {
    margin-bottom: auto !important;
  }
}

.unslate_co--footer {
  position: fixed !important;
  width: 100%;
  background-color: #010000;
  color: #fff;
  left: 0;
  right: 0;
  bottom: 0px;
  z-index: -1;
  height: 400px;
}

@media (max-width: 991.98px) {
  .unslate_co--footer {
    height: auto !important;
    position: relative !important;
    z-index: 0;
  }
}

.btn {
  font-size: 11px;
  border-radius: 30px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 30px;
  padding-right: 30px;
  letter-spacing: .1rem;
  text-transform: uppercase;
  font-weight: 900;
}

.btn:active, .btn:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn.btn-outline-pill.btn-custom-light {
  color: #fff;
  border: 2px solid rgba(255, 255, 255, 0.5);
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease;
}

.btn.btn-outline-pill.btn-custom-light:hover, .btn.btn-outline-pill.btn-custom-light:active, .btn.btn-outline-pill.btn-custom-light:focus {
  border-color: #fff;
}

.btn.btn-bg-white--hover:hover {
  background-color: #fff;
  color: #000;
}

#unslate_co--overlayer {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 7100;
  background: #000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.site-loader-wrap {
  z-index: 7700;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.unslate_co--site-nav {
  padding-top: 30px;
  padding-bottom: 30px;
  position: absolute;
  left: 0;
  font-weight: 400;
  z-index: 1002;
  width: 100%;
  top: 0;
}

.unslate_co--site-nav .container {
  position: relative;
}

.unslate_co--site-nav .site-logo {
  z-index: 99;
}

.unslate_co--site-nav .site-logo.pos-absolute {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.unslate_co--site-nav .site-nav-ul-wrap {
  width: 100%;
  margin: 0 auto;
  z-index: 1;
  position: relative;
}

.unslate_co--site-nav .unslate_co--site-logo {
  font-size: 1.7rem;
  color: #fff;
  font-weight: 700;
}

.unslate_co--site-nav .unslate_co--site-logo span {
  color: #fff;
}

.unslate_co--site-nav .site-nav-ul, .unslate_co--site-nav .site-nav-ul-none-onepage {
  text-align: center;
}

.unslate_co--site-nav .site-nav-ul, .unslate_co--site-nav .site-nav-ul > li, .unslate_co--site-nav .site-nav-ul-none-onepage, .unslate_co--site-nav .site-nav-ul-none-onepage > li {
  padding: 0;
  margin: 0;
  list-style: none;
}

.unslate_co--site-nav .site-nav-ul > li, .unslate_co--site-nav .site-nav-ul-none-onepage > li {
  text-align: left;
  display: inline-block;
}

.unslate_co--site-nav .site-nav-ul > li > a, .unslate_co--site-nav .site-nav-ul-none-onepage > li > a {
  padding: 10px 7px;
  display: block;
  color: #fff;
  font-size: 14px;
  position: relative;
}

.unslate_co--site-nav .site-nav-ul > li > a:before, .unslate_co--site-nav .site-nav-ul-none-onepage > li > a:before {
  left: 7px;
  right: 7px;
  content: "";
  position: absolute;
  height: 1px;
  background: #fff;
  bottom: 0;
  width: 0%;
  -webkit-transition: .2s width ease-in-out;
  -o-transition: .2s width ease-in-out;
  transition: .2s width ease-in-out;
}

.unslate_co--site-nav .site-nav-ul > li > a:hover, .unslate_co--site-nav .site-nav-ul-none-onepage > li > a:hover {
  color: #fff;
}

.unslate_co--site-nav .site-nav-ul > li > a:hover:before, .unslate_co--site-nav .site-nav-ul-none-onepage > li > a:hover:before {
  width: calc(100% - 14px);
}

.unslate_co--site-nav .site-nav-ul > li > a.active, .unslate_co--site-nav .site-nav-ul-none-onepage > li > a.active {
  position: relative;
}

.unslate_co--site-nav .site-nav-ul > li > a.active:before, .unslate_co--site-nav .site-nav-ul-none-onepage > li > a.active:before {
  color: #fff;
  width: calc(100% - 14px);
}

.unslate_co--site-nav .site-nav-ul > li.has-children, .unslate_co--site-nav .site-nav-ul-none-onepage > li.has-children {
  position: relative;
}

.unslate_co--site-nav .site-nav-ul > li.has-children > .dropdown, .unslate_co--site-nav .site-nav-ul > li.has-children > ul, .unslate_co--site-nav .site-nav-ul-none-onepage > li.has-children > .dropdown, .unslate_co--site-nav .site-nav-ul-none-onepage > li.has-children > ul {
  visibility: hidden;
  opacity: 0;
  z-index: 2;
  position: absolute;
  left: 0;
  top: 100%;
  min-width: 200px;
  background: #fff;
  padding: 20px 0px;
  margin-top: 20px;
  -webkit-box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.2);
  -webkit-transition: 0.2s 0s;
  -o-transition: 0.2s 0s;
  transition: 0.2s 0s;
}

.unslate_co--site-nav .site-nav-ul > li.has-children > .dropdown > li, .unslate_co--site-nav .site-nav-ul > li.has-children > ul > li, .unslate_co--site-nav .site-nav-ul-none-onepage > li.has-children > .dropdown > li, .unslate_co--site-nav .site-nav-ul-none-onepage > li.has-children > ul > li {
  padding: 0;
  margin: 0;
  list-style: none;
  line-height: 1.5;
}

.unslate_co--site-nav .site-nav-ul > li.has-children > .dropdown > li, .unslate_co--site-nav .site-nav-ul > li.has-children > ul > li, .unslate_co--site-nav .site-nav-ul-none-onepage > li.has-children > .dropdown > li, .unslate_co--site-nav .site-nav-ul-none-onepage > li.has-children > ul > li {
  display: block;
  /* 2nd level hover*/
}

.unslate_co--site-nav .site-nav-ul > li.has-children > .dropdown > li a, .unslate_co--site-nav .site-nav-ul > li.has-children > ul > li a, .unslate_co--site-nav .site-nav-ul-none-onepage > li.has-children > .dropdown > li a, .unslate_co--site-nav .site-nav-ul-none-onepage > li.has-children > ul > li a {
  font-size: 14px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 30px;
  padding-right: 30px;
  display: block;
  color: gray;
}

.unslate_co--site-nav .site-nav-ul > li.has-children > .dropdown > li a:hover, .unslate_co--site-nav .site-nav-ul > li.has-children > ul > li a:hover, .unslate_co--site-nav .site-nav-ul-none-onepage > li.has-children > .dropdown > li a:hover, .unslate_co--site-nav .site-nav-ul-none-onepage > li.has-children > ul > li a:hover {
  color: #000;
  background: #f9f9f9;
  padding-left: 30px;
}

.unslate_co--site-nav .site-nav-ul > li.has-children > .dropdown > li.has-children, .unslate_co--site-nav .site-nav-ul > li.has-children > ul > li.has-children, .unslate_co--site-nav .site-nav-ul-none-onepage > li.has-children > .dropdown > li.has-children, .unslate_co--site-nav .site-nav-ul-none-onepage > li.has-children > ul > li.has-children {
  position: relative;
}

.unslate_co--site-nav .site-nav-ul > li.has-children > .dropdown > li.has-children > a, .unslate_co--site-nav .site-nav-ul > li.has-children > ul > li.has-children > a, .unslate_co--site-nav .site-nav-ul-none-onepage > li.has-children > .dropdown > li.has-children > a, .unslate_co--site-nav .site-nav-ul-none-onepage > li.has-children > ul > li.has-children > a {
  position: relative;
}

.unslate_co--site-nav .site-nav-ul > li.has-children > .dropdown > li.has-children > a:before, .unslate_co--site-nav .site-nav-ul > li.has-children > ul > li.has-children > a:before, .unslate_co--site-nav .site-nav-ul-none-onepage > li.has-children > .dropdown > li.has-children > a:before, .unslate_co--site-nav .site-nav-ul-none-onepage > li.has-children > ul > li.has-children > a:before {
  position: absolute;
  content: "\e315";
  font-size: 16px;
  top: 50%;
  color: #000;
  right: 20px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-family: 'icomoon';
}

.unslate_co--site-nav .site-nav-ul > li.has-children > .dropdown > li.has-children > .dropdown, .unslate_co--site-nav .site-nav-ul > li.has-children > .dropdown > li.has-children > ul, .unslate_co--site-nav .site-nav-ul > li.has-children > ul > li.has-children > .dropdown, .unslate_co--site-nav .site-nav-ul > li.has-children > ul > li.has-children > ul, .unslate_co--site-nav .site-nav-ul-none-onepage > li.has-children > .dropdown > li.has-children > .dropdown, .unslate_co--site-nav .site-nav-ul-none-onepage > li.has-children > .dropdown > li.has-children > ul, .unslate_co--site-nav .site-nav-ul-none-onepage > li.has-children > ul > li.has-children > .dropdown, .unslate_co--site-nav .site-nav-ul-none-onepage > li.has-children > ul > li.has-children > ul {
  z-index: 1;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  left: 0;
  z-index: 22;
  list-style: none;
  top: 0;
  left: 100%;
  min-width: 200px;
  background: #fff;
  padding: 20px 0px;
  margin-top: 20px;
  -webkit-box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.2);
  -webkit-transition: 0.2s 0s;
  -o-transition: 0.2s 0s;
  transition: 0.2s 0s;
}

.unslate_co--site-nav .site-nav-ul > li.has-children > .dropdown > li.has-children > .dropdown a, .unslate_co--site-nav .site-nav-ul > li.has-children > .dropdown > li.has-children > ul a, .unslate_co--site-nav .site-nav-ul > li.has-children > ul > li.has-children > .dropdown a, .unslate_co--site-nav .site-nav-ul > li.has-children > ul > li.has-children > ul a, .unslate_co--site-nav .site-nav-ul-none-onepage > li.has-children > .dropdown > li.has-children > .dropdown a, .unslate_co--site-nav .site-nav-ul-none-onepage > li.has-children > .dropdown > li.has-children > ul a, .unslate_co--site-nav .site-nav-ul-none-onepage > li.has-children > ul > li.has-children > .dropdown a, .unslate_co--site-nav .site-nav-ul-none-onepage > li.has-children > ul > li.has-children > ul a {
  padding-left: 30px;
  padding-right: 30px;
}

.unslate_co--site-nav .site-nav-ul > li.has-children > .dropdown > li:hover, .unslate_co--site-nav .site-nav-ul > li.has-children > .dropdown > li:focus, .unslate_co--site-nav .site-nav-ul > li.has-children > .dropdown > li:active, .unslate_co--site-nav .site-nav-ul > li.has-children > ul > li:hover, .unslate_co--site-nav .site-nav-ul > li.has-children > ul > li:focus, .unslate_co--site-nav .site-nav-ul > li.has-children > ul > li:active, .unslate_co--site-nav .site-nav-ul-none-onepage > li.has-children > .dropdown > li:hover, .unslate_co--site-nav .site-nav-ul-none-onepage > li.has-children > .dropdown > li:focus, .unslate_co--site-nav .site-nav-ul-none-onepage > li.has-children > .dropdown > li:active, .unslate_co--site-nav .site-nav-ul-none-onepage > li.has-children > ul > li:hover, .unslate_co--site-nav .site-nav-ul-none-onepage > li.has-children > ul > li:focus, .unslate_co--site-nav .site-nav-ul-none-onepage > li.has-children > ul > li:active {
  cursor: pointer;
}

.unslate_co--site-nav .site-nav-ul > li.has-children > .dropdown > li:hover > .dropdown, .unslate_co--site-nav .site-nav-ul > li.has-children > .dropdown > li:focus > .dropdown, .unslate_co--site-nav .site-nav-ul > li.has-children > .dropdown > li:active > .dropdown, .unslate_co--site-nav .site-nav-ul > li.has-children > ul > li:hover > .dropdown, .unslate_co--site-nav .site-nav-ul > li.has-children > ul > li:focus > .dropdown, .unslate_co--site-nav .site-nav-ul > li.has-children > ul > li:active > .dropdown, .unslate_co--site-nav .site-nav-ul-none-onepage > li.has-children > .dropdown > li:hover > .dropdown, .unslate_co--site-nav .site-nav-ul-none-onepage > li.has-children > .dropdown > li:focus > .dropdown, .unslate_co--site-nav .site-nav-ul-none-onepage > li.has-children > .dropdown > li:active > .dropdown, .unslate_co--site-nav .site-nav-ul-none-onepage > li.has-children > ul > li:hover > .dropdown, .unslate_co--site-nav .site-nav-ul-none-onepage > li.has-children > ul > li:focus > .dropdown, .unslate_co--site-nav .site-nav-ul-none-onepage > li.has-children > ul > li:active > .dropdown {
  -webkit-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
  margin-top: 0px;
  visibility: visible;
  opacity: 1;
}

.unslate_co--site-nav .site-nav-ul > li.has-children:hover, .unslate_co--site-nav .site-nav-ul > li.has-children:focus, .unslate_co--site-nav .site-nav-ul > li.has-children:active, .unslate_co--site-nav .site-nav-ul-none-onepage > li.has-children:hover, .unslate_co--site-nav .site-nav-ul-none-onepage > li.has-children:focus, .unslate_co--site-nav .site-nav-ul-none-onepage > li.has-children:active {
  cursor: pointer;
}

.unslate_co--site-nav .site-nav-ul > li.has-children:hover > .dropdown, .unslate_co--site-nav .site-nav-ul > li.has-children:focus > .dropdown, .unslate_co--site-nav .site-nav-ul > li.has-children:active > .dropdown, .unslate_co--site-nav .site-nav-ul-none-onepage > li.has-children:hover > .dropdown, .unslate_co--site-nav .site-nav-ul-none-onepage > li.has-children:focus > .dropdown, .unslate_co--site-nav .site-nav-ul-none-onepage > li.has-children:active > .dropdown {
  -webkit-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
  margin-top: 0px;
  visibility: visible;
  opacity: 1;
}

.unslate_co--site-nav .site-nav-ul > li.active > a, .unslate_co--site-nav .site-nav-ul-none-onepage > li.active > a {
  position: relative;
}

.unslate_co--site-nav .site-nav-ul > li.active > a:before, .unslate_co--site-nav .site-nav-ul-none-onepage > li.active > a:before {
  color: #fff;
  width: calc(100% - 40px);
}

.unslate_co--site-nav .burger-toggle-menu {
  display: block;
  width: 36px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
  z-index: 99;
}

.unslate_co--site-nav .burger-toggle-menu span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: #fff;
  border-radius: 0px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

.unslate_co--site-nav .burger-toggle-menu span:nth-child(1) {
  top: 0px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  -ms-transform-origin: left center;
  transform-origin: left center;
}

.unslate_co--site-nav .burger-toggle-menu span:nth-child(2) {
  top: 10px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  -ms-transform-origin: left center;
  transform-origin: left center;
}

.unslate_co--site-nav .burger-toggle-menu span:nth-child(3) {
  top: 20px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  -ms-transform-origin: left center;
  transform-origin: left center;
}

.unslate_co--site-nav .burger-toggle-menu.open span:nth-child(1) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  top: -3px;
  left: 0;
}

.unslate_co--site-nav .burger-toggle-menu.open span:nth-child(2) {
  width: 0%;
  opacity: 0;
}

.unslate_co--site-nav .burger-toggle-menu.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 23px;
  left: 0;
}

.unslate_co--site-nav.scrolled {
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  margin-top: -130px;
  background: #fff !important;
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  padding-top: 5px;
  padding-bottom: 5px;
}

.unslate_co--site-nav.scrolled .burger-toggle-menu {
  top: 10px;
}

.unslate_co--site-nav.scrolled .burger-toggle-menu span {
  background: #000;
}

.unslate_co--site-nav.scrolled .unslate_co--site-logo {
  color: #000;
}

.unslate_co--site-nav.scrolled .site-nav-ul, .unslate_co--site-nav.scrolled .site-nav-ul-none-onepage {
  text-align: center;
}

.unslate_co--site-nav.scrolled .site-nav-ul, .unslate_co--site-nav.scrolled .site-nav-ul > li, .unslate_co--site-nav.scrolled .site-nav-ul-none-onepage, .unslate_co--site-nav.scrolled .site-nav-ul-none-onepage > li {
  padding: 0;
  margin: 0;
  list-style: none;
}

.unslate_co--site-nav.scrolled .site-nav-ul > li, .unslate_co--site-nav.scrolled .site-nav-ul-none-onepage > li {
  text-align: left;
  display: inline-block;
}

.unslate_co--site-nav.scrolled .site-nav-ul > li > a, .unslate_co--site-nav.scrolled .site-nav-ul-none-onepage > li > a {
  display: block;
  color: #000;
}

.unslate_co--site-nav.scrolled .site-nav-ul > li > a:hover, .unslate_co--site-nav.scrolled .site-nav-ul-none-onepage > li > a:hover {
  color: #fff;
}

.unslate_co--site-nav.scrolled .site-nav-ul > li > a:before, .unslate_co--site-nav.scrolled .site-nav-ul-none-onepage > li > a:before {
  background: #fff;
}

.unslate_co--site-nav.scrolled .site-nav-ul > li > a.active, .unslate_co--site-nav.scrolled .site-nav-ul-none-onepage > li > a.active {
  color: #fff;
}

.unslate_co--site-nav.scrolled .site-nav-ul > li > a.active:before, .unslate_co--site-nav.scrolled .site-nav-ul-none-onepage > li > a.active:before {
  background: #fff;
}

.unslate_co--site-nav.scrolled.awake {
  margin-top: 0px;
  -webkit-transition: .3s all ease-out;
  -o-transition: .3s all ease-out;
  transition: .3s all ease-out;
}

.unslate_co--site-nav.scrolled.sleep {
  -webkit-transition: .3s all ease-out;
  -o-transition: .3s all ease-out;
  transition: .3s all ease-out;
}

/* Mobile nav */
.unslate_co--site-mobile-menu {
  position: fixed;
  z-index: 1009;
  height: 100vh;
  background: #fff;
  width: 280px;
  overflow: auto;
  padding-top: 30px;
  padding-bottom: 70px;
  width: 280px;
  left: 0;
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-transition: 1s -webkit-transform cubic-bezier(0.23, 1, 0.32, 1);
  transition: 1s -webkit-transform cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: 1s transform cubic-bezier(0.23, 1, 0.32, 1);
  transition: 1s transform cubic-bezier(0.23, 1, 0.32, 1);
  transition: 1s transform cubic-bezier(0.23, 1, 0.32, 1), 1s -webkit-transform cubic-bezier(0.23, 1, 0.32, 1);
}

.offcanvas .unslate_co--site-mobile-menu {
  -webkit-transform: translateX(0%);
  -ms-transform: translateX(0%);
  transform: translateX(0%);
}

.unslate_co--site-mobile-menu .close-wrap {
  margin-right: 20px;
  position: relative;
}

.unslate_co--site-mobile-menu .close-wrap a {
  margin-right: 15px;
}

.unslate_co--site-mobile-menu .close-wrap a:hover {
  opacity: .5;
}

.unslate_co--site-mobile-menu .close-wrap a .close-label {
  margin-right: 20px;
  color: #000;
}

.unslate_co--site-mobile-menu .close-wrap a .close-times {
  position: relative;
  margin-top: 4px;
  right: 0;
}

.unslate_co--site-mobile-menu .close-wrap a .close-times .bar1, .unslate_co--site-mobile-menu .close-wrap a .close-times .bar2 {
  width: 2px;
  height: 20px;
  background: #000;
  position: absolute;
}

.unslate_co--site-mobile-menu .close-wrap a .close-times .bar1 {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.unslate_co--site-mobile-menu .close-wrap a .close-times .bar2 {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.unslate_co--site-mobile-menu ul, .unslate_co--site-mobile-menu ul li {
  padding: 0;
  margin: 0;
  position: relative;
}

.unslate_co--site-mobile-menu ul a, .unslate_co--site-mobile-menu ul li a {
  position: relative;
  display: block;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  color: #000;
}

.unslate_co--site-mobile-menu ul a:hover, .unslate_co--site-mobile-menu ul li a:hover {
  color: #fff;
}

.unslate_co--site-mobile-menu ul > li > ul > li > a {
  position: relative;
  padding-left: 30px;
  font-size: 14px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.unslate_co--site-mobile-menu ul > li > ul > li > ul > li > a {
  position: relative;
  padding-left: 50px;
  font-size: 14px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.unslate_co--site-mobile-menu ul > li.has-children .arrow-collapse {
  position: absolute;
  right: 10px;
  top: 0px;
  z-index: 20;
  width: 45px;
  height: 45px;
  text-align: center;
  cursor: pointer;
  border-radius: 50%;
}

.unslate_co--site-mobile-menu ul > li.has-children .arrow-collapse:before {
  font-size: 11px !important;
  z-index: 20;
  font-family: "icomoon";
  content: "\f078";
  position: absolute;
  top: 50%;
  left: 50%;
  color: #000;
  -webkit-transform: translate(-50%, -50%) rotate(-180deg);
  -ms-transform: translate(-50%, -50%) rotate(-180deg);
  transform: translate(-50%, -50%) rotate(-180deg);
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease;
}

.unslate_co--site-mobile-menu ul > li.has-children .arrow-collapse.collapsed:before {
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.unslate_co--site-mobile-menu ul > li.has-children > ul > li.has-children .arrow-collapse {
  top: -10px;
}

.cover-v1 {
  background-size: cover;
  background-position: center center;
  background-repeat: repeat;
  width: 100%;
}

.cover-v1.gradient-bottom-black {
  position: relative;
}

.cover-v1.gradient-bottom-black:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  background: rgba(0, 0, 0, 0);
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 79%, rgba(0, 0, 0, 0.05) 80%, black 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(79%, rgba(0, 0, 0, 0)), color-stop(80%, rgba(0, 0, 0, 0.05)), color-stop(100%, black));
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 79%, rgba(0, 0, 0, 0.05) 80%, black 100%);
  background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 79%, rgba(0, 0, 0, 0.05) 80%, black 100%);
  background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) 79%, rgba(0, 0, 0, 0.05) 80%, black 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(79%, rgba(0, 0, 0, 0)), color-stop(80%, rgba(0, 0, 0, 0.05)), to(black));
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 79%, rgba(0, 0, 0, 0.05) 80%, black 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=0 );
}

.cover-v1.overlay {
  position: relative;
}

.cover-v1.overlay:before {
  z-index: 9;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  background: rgba(0, 0, 0, 0.4);
}

.cover-v1.overlay .mbYTP_wrapper {
  z-index: -1;
}

.cover-v1 .container {
  z-index: 9;
  position: relative;
}

.cover-v1 .container, .cover-v1 .container > .row {
  height: 100vh;
  min-height: 650px;
}

.cover-v1 .heading {
  font-size: 5rem;
  font-weight: 900;
  color: #fff;
}

@media (max-width: 991.98px) {
  .cover-v1 .container, .cover-v1 .container > .row {
  height: 88vh;
  min-height: 650px;
}
  .cover-v1 .heading {
    font-size: 3rem;
  }
}

.cover-v1 .blog-heading {
  font-weight: 900;
  color: #fff;
  font-size: 4rem;
}

@media (max-width: 991.98px) {
  .cover-v1 .blog-heading {
    font-size: 2rem;
  }
}

.cover-v1 .post-meta {
  font-size: 18px;
}

.cover-v1 .subheading {
  font-size: 33px;
  color: #fff;
}

@media (max-width: 991.98px) {
  .cover-v1 .subheading {
    font-size: 24px;
  }
}

.mouse-wrap {
  position: relative;
  display: inline-block;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 50px;
  z-index: 9;
}

.mouse-wrap:active, .mouse-wrap:active > .mouse-label, .mouse-wrap:focus, .mouse-wrap:focus > .mouse-label {
  outline: none;
}

.mouse-wrap.mouse-with-slider {
  bottom: -50px;
}

.mouse-wrap .mouse-label {
  top: 0;
  color: #fff;
  font-size: 10px;
  font-weight: 700;
  letter-spacing: .05rem;
  text-transform: uppercase;
}

.mouse-wrap.dark .mouse-label {
  color: #000;
}

.mouse-wrap.dark .mouse {
  border: 2px solid #000;
}

.mouse-wrap.dark .mouse .scroll {
  background: #000;
}

.mouse-wrap .mouse {
  position: absolute;
  width: 22px;
  height: 42px;
  bottom: 30px;
  display: block;
  left: 50%;
  margin-left: -12px;
  border-radius: 15px;
  border: 2px solid #fff;
  -webkit-animation: intro 2s;
  animation: intro 2s;
}

.mouse-wrap .mouse .scroll {
  display: block;
  width: 3px;
  height: 3px;
  margin: 6px auto;
  border-radius: 4px;
  background: #fff;
  -webkit-animation: finger 2s infinite;
  animation: finger 2s infinite;
}

@-webkit-keyframes intro {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes intro {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@-webkit-keyframes finger {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
}

@keyframes finger {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
}

.hero-slider-wrap {
  position: relative;
}

.grid-item {
  position: relative;
}

.grid-item:before {
  content: '';
  display: inline-block;
  padding-top: 20rem;
}

.grid-item img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  -o-object-fit: cover;
  object-fit: cover;
}

.blog-item {
  position: relative;
}

.blog-item img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  -o-object-fit: cover;
  object-fit: cover;
}

.portfolio-item {
  overflow: hidden;
}

.portfolio-item img {
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease;
  position: relative;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.portfolio-item:hover img {
  -webkit-transform: scale(1.09);
  -ms-transform: scale(1.09);
  transform: scale(1.09);
}

.portfolio-item, .blog-item {
  display: block;
  overflow: hidden;
  position: relative;
}

.portfolio-item .overlay, .blog-item .overlay {
  position: relative;
  z-index: 8;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease;
}

.portfolio-item .overlay, .portfolio-item .overlay:before, .blog-item .overlay, .blog-item .overlay:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.portfolio-item .overlay:before, .blog-item .overlay:before {
  background: rgba(0, 0, 0, 0.5);
  content: "";
}

.portfolio-item .wrap-icon, .blog-item .wrap-icon {
  position: absolute;
  right: 20px;
  color: #fff;
  top: 20px;
}

.portfolio-item .portfolio-item-content, .blog-item .portfolio-item-content {
  position: absolute;
  bottom: 20px;
  left: 20px;
  right: 20px;
}

.portfolio-item .portfolio-item-content h3, .portfolio-item .portfolio-item-content p, .blog-item .portfolio-item-content h3, .blog-item .portfolio-item-content p {
  color: #fff;
  margin: 0;
  padding: 12px;
}

.portfolio-item .portfolio-item-content h3, .blog-item .portfolio-item-content h3 {
  font-size: 18px;
}

.portfolio-item .portfolio-item-content p, .blog-item .portfolio-item-content p {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
  font-family: "Arimo", sans-serif;
}

.portfolio-item:hover .overlay, .blog-item:hover .overlay {
  opacity: 1;
  visibility: visible;
}

.blog-item {
  margin-left: 0 !important;
}

.blog-item .overlay {
  opacity: 1;
  visibility: visible;
}

.blog-item:hover .overlay {
  opacity: 0;
  visibility: hidden;
}

.filter-wrap {
  position: relative;
}

.filter-wrap .filter {
  font-size: 14px;
  color: #fff;
}

.filter-wrap .filter a {
  margin-left: 10px;
  color: rgba(255, 255, 255, 0.5);
}

.filter-wrap .filter a:hover {
  color: #fff;
}

.filter-wrap .filter a.active {
  color: #fff;
}

@media (max-width: 991.98px) {
  .filter-wrap .filter {
    background: #000;
    z-index: 9;
    position: absolute;
    min-width: 150px;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease;
    right: 0;
    padding: 20px;
    top: 10px;
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
    border-radius: 4px;
  }
  .filter-wrap .filter.active {
    opacity: 1;
    visibility: visible;
  }
  .filter-wrap .filter a {
    display: block;
  }
}

/* Isotope Transitions
------------------------------- */
.isotope,
.isotope .item {
  -webkit-transition-duration: 0.8s;
  -moz-transition-duration: 0.8s;
  -ms-transition-duration: 0.8s;
  -o-transition-duration: 0.8s;
  transition-duration: 0.8s;
}

.isotope {
  -webkit-transition-property: height, width;
  -moz-transition-property: height, width;
  -ms-transition-property: height, width;
  -o-transition-property: height, width;
  transition-property: height, width;
}

.isotope .item {
  -webkit-transition-property: -webkit-transform, opacity;
  -moz-transition-property: -moz-transform, opacity;
  -ms-transition-property: -ms-transform, opacity;
  -o-transition-property: top, left, opacity;
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  -o-transition-property: transform, opacity;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
}

.unslate_co--section {
  padding: 3.5rem 0;
}

@media (max-width: 767.98px) {
  .unslate_co--section {
    padding: 2rem 0;
  }
}

.unslate_co--section a:hover {
  color: #fff;
}

.heading-h2 {
  font-size: 32px;
  color: #fff;
  font-weight: 900;
}

.heading-h2.divider:before {
  position: absolute;
  content: "";
}

.heading-h3 {
  font-size: 26px;
  font-weight: 900;
  color: #fff;
}

.lead {
  font-size: 18px;
  line-height: 30px;
}

.relative {
  position: relative;
}

figure.dotted-bg {
  position: relative;
}

figure.dotted-bg:before {
  z-index: -1;
  content: "";
  position: absolute;
  bottom: -50px;
  right: -50px;
  width: 268px;
  height: 224px;
  /* background-image: url("../images/dotted_light.png"); */
  background-repeat: no-repeat;
}

@media (max-width: 991.98px) {
  figure.dotted-bg:before {
    right: 0px;
  }
}

.gutter-v1 {
  margin-right: -1px !important;
  margin-left: -1px !important;
}

@media (max-width: 991.98px) {
  .gutter-v1 {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }
}

.gutter-v1 > .col,
.gutter-v1 > [class*="col-"] {
  padding-right: 1px !important;
  padding-left: 1px !important;
}

@media (max-width: 991.98px) {
  .gutter-v1 > .col,
  .gutter-v1 > [class*="col-"] {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
}

.gutter-v2 {
  margin-right: -2px !important;
  margin-left: -2px !important;
}

@media (max-width: 991.98px) {
  .gutter-v2 {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }
}

.gutter-v2 > .col,
.gutter-v2 > [class*="col-"] {
  margin-bottom: 4px;
  padding-right: 2px !important;
  padding-left: 2px !important;
}

@media (max-width: 991.98px) {
  .gutter-v2 > .col,
  .gutter-v2 > [class*="col-"] {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
}

.gutter-v3 {
  margin-left: -60px !important;
  margin-right: -60px !important;
}

@media (max-width: 991.98px) {
  .gutter-v3 {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }
}

.gutter-v3 > .col,
.gutter-v3 > [class*="col-"] {
  padding-right: 60px !important;
  padding-left: 60px !important;
}

@media (max-width: 991.98px) {
  .gutter-v3 > .col,
  .gutter-v3 > [class*="col-"] {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
}

.gutter-v4 {
  margin-right: -10px !important;
  margin-left: -10px !important;
}

.gutter-v4 > .col,
.gutter-v4 > [class*="col-"] {
  margin-bottom: 20px;
  padding-right: 10px !important;
  padding-left: 10px !important;
}

.gutter-isotope-item {
  margin-right: -15px !important;
  margin-left: -15px !important;
}

@media (max-width: 991.98px) {
  .gutter-isotope-item {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }
}

.gutter-isotope-item > .col,
.gutter-isotope-item > [class*="col-"] {
  padding-right: 15px !important;
  padding-left: 15px !important;
}

@media (max-width: 991.98px) {
  .gutter-isotope-item > .col,
  .gutter-isotope-item > [class*="col-"] {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
}

.isotope-mb-2 {
  margin-bottom: 30px !important;
}

.feature-v1 {
  line-height: 28px;
}

@media (max-width: 767.98px) {
  .feature-v1 {
    text-align: center;
  }
}

.feature-v1 h3 {
  font-size: 22px;
  font-weight: 900;
}

.feature-v1 p {
  font-size: 16px;
}

.counter-v1 .number-wrap {
  display: block;
}

.counter-v1 .number-wrap .number {
  font-size: 69px;
  font-weight: 900;
  font-family: "Arimo", sans-serif;
  color: #fff;
}

.counter-v1 .number-wrap .append-text {
  position: relative;
  top: -30px;
  font-size: 28px;
  font-weight: 900;
}

.counter-v1 .counter-label {
  text-transform: uppercase;
  letter-spacing: .1rem;
  font-size: 11px;
  font-weight: 900;
}

.testimonial-v1 {
  text-align: center;
  z-index: 9;
}

.testimonial-v1 .testimonial-inner-bg {
  padding: 50px;
  padding-bottom: 70px;
  background-color: #212121;
  text-align: center;
  font-size: 20px;
  line-height: 40px;
  position: relative;
  z-index: 1;
  -webkit-box-shadow: 0px 30px 0 -20px #fff;
  box-shadow: 0px 30px 0 -20px #fff;
}

@media (max-width: 991.98px) {
  .testimonial-v1 .testimonial-inner-bg {
    font-size: 16px;
    line-height: 32px;
    padding: 30px 30px 50px 30px;
  }
}

.testimonial-v1 .testimonial-inner-bg .quote {
  display: block;
  font-size: 80px;
  font-family: 'Georgia', serif;
}

.testimonial-v1 .testimonial-author-info {
  z-index: 2;
  margin-top: -45px;
  position: relative;
}

.testimonial-v1 .testimonial-author-info img {
  width: 90px;
  margin: 0 auto 30px auto;
  border-radius: 50%;
}

.testimonial-v1 .testimonial-author-info h3 {
  font-size: 20px;
  margin: 0;
}

.testimonial-v1 .testimonial-author-info .position {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
}

.testimonial-slider .owl-stage, .single-slider .owl-stage {
  overflow: hidden;
}

@media (max-width: 991.98px) {
  .testimonial-slider .owl-nav, .single-slider .owl-nav {
    display: none;
  }
}

.testimonial-slider .owl-nav .owl-prev,
.testimonial-slider .owl-nav .owl-next, .single-slider .owl-nav .owl-prev,
.single-slider .owl-nav .owl-next {
  position: absolute;
  bottom: -70px;
  z-index: 99;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #fff !important;
}

.testimonial-slider .owl-nav .owl-prev span,
.testimonial-slider .owl-nav .owl-next span, .single-slider .owl-nav .owl-prev span,
.single-slider .owl-nav .owl-next span {
  font-size: 30px;
}

.testimonial-slider .owl-nav .owl-prev:hover,
.testimonial-slider .owl-nav .owl-next:hover, .single-slider .owl-nav .owl-prev:hover,
.single-slider .owl-nav .owl-next:hover {
  color: #fff;
}

.testimonial-slider .owl-nav .owl-prev:active, .testimonial-slider .owl-nav .owl-prev:focus,
.testimonial-slider .owl-nav .owl-next:active,
.testimonial-slider .owl-nav .owl-next:focus, .single-slider .owl-nav .owl-prev:active, .single-slider .owl-nav .owl-prev:focus,
.single-slider .owl-nav .owl-next:active,
.single-slider .owl-nav .owl-next:focus {
  outline: none;
}

.testimonial-slider .owl-nav .owl-prev, .single-slider .owl-nav .owl-prev {
  left: 60px;
}

.testimonial-slider .owl-nav .owl-next, .single-slider .owl-nav .owl-next {
  right: 60px;
}

.testimonial-slider .owl-dots, .single-slider .owl-dots {
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: 50px;
}

.testimonial-slider .owl-dots .owl-dot, .single-slider .owl-dots .owl-dot {
  display: inline-block;
}

.testimonial-slider .owl-dots .owl-dot > span, .single-slider .owl-dots .owl-dot > span {
  margin: 4px;
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.5);
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease;
}

.testimonial-slider .owl-dots .owl-dot.active > span, .single-slider .owl-dots .owl-dot.active > span {
  background: white;
}

.testimonial-slider .owl-dots .owl-dot:active, .testimonial-slider .owl-dots .owl-dot:focus, .single-slider .owl-dots .owl-dot:active, .single-slider .owl-dots .owl-dot:focus {
  outline: none;
}

.testimonial-slider .owl-dots {
  bottom: -50px;
}

.single-slider {
  margin-bottom: 90px;
}

.single-slider .owl-prev, .single-slider .owl-next {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 99;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.2) !important;
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease;
}

.single-slider .owl-prev:hover, .single-slider .owl-next:hover {
  background: rgba(0, 0, 0, 0.9) !important;
}

.single-slider .owl-prev span, .single-slider .owl-next span {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.single-slider .owl-prev span:before, .single-slider .owl-next span:before {
  font-size: 40px;
}

.blog-post-entry {
  margin-bottom: 30px;
}

.blog-post-entry .post-meta {
  font-family: "Arimo", sans-serif;
}

.form-outline-style-v1 .form-group {
  position: relative;
  margin-bottom: 50px;
}

.form-outline-style-v1 .form-group label {
  position: absolute;
  top: 5px;
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease;
}

.form-outline-style-v1 .form-group .form-control {
  border: none;
  background: none;
  padding-left: 0;
  padding-right: 0;
  border-radius: 0;
  color: #fff;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.form-outline-style-v1 .form-group .form-control:active, .form-outline-style-v1 .form-group .form-control:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-bottom: 1px solid white;
}

.form-outline-style-v1 .form-group label {
  font-size: 12px;
  display: block;
  margin-bottom: 0;
  color: #b3b3b3;
  text-transform: uppercase;
  font-weight: 900;
}

.form-outline-style-v1 .form-group.focus {
  background: #fff;
}

.form-outline-style-v1 .form-group.field--not-empty label {
  margin-top: -25px;
}

.form-control {
  border: none;
  background: none;
  padding-left: 0;
  padding-right: 0;
  border-radius: 0;
  color: #fff;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.form-control:active, .form-control:focus {
  background: none;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-bottom: 1px solid white;
}

@media (max-width: 767.98px) {
  .contact-info-v1 {
    text-align: center;
  }
}

.contact-info-v1 > div {
  margin-bottom: 30px;
}

.contact-info-v1 .contact-info-label {
  font-size: 11px;
  letter-spacing: .1rem;
  font-weight: 900;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.5);
}

.contact-info-v1 .contact-info-val {
  font-size: 20px;
  color: #fff;
}

.portfolio-single-inner .heading-portfolio-single-h2 {
  margin-bottom: 40px;
  font-size: 50px;
  font-weight: 900;
}

@media (max-width: 991.98px) {
  .portfolio-single-inner .heading-portfolio-single-h2 {
    font-size: 30px;
  }
}

#portfolio-single-holder .portfolio-single-inner {
  position: relative;
  opacity: 0;
  display: none;
}

.unslate_co--close-portfolio {
  position: absolute;
  top: -10px;
  cursor: pointer;
  z-index: 9;
  padding: 10px 10px 10px 10px;
  right: -10px;
  border: 2px solid transparent;
  background-color: #212121;
  border-radius: 30px;
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease;
}

.unslate_co--close-portfolio .close-portfolio-label {
  font-size: 14px;
  top: 25px;
  right: -10px;
  width: 150px;
  position: absolute;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease;
}

.unslate_co--close-portfolio .wrap-icon-close {
  font-size: 30px;
  position: relative;
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease;
}

.unslate_co--close-portfolio:hover .wrap-icon-close {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.unslate_co--close-portfolio:hover .close-portfolio-lanel {
  opacity: 1;
  left: -10px;
  visibility: visible;
}

.unslate_co--close-portfolio:hover, .unslate_co--close-portfolio:focus {
  border-color: #333333;
  background-color: #2e2e2e;
}

.unslate_co--close-portfolio:hover .close-portfolio-label, .unslate_co--close-portfolio:focus .close-portfolio-label {
  right: 20px;
  opacity: 1;
  visibility: visible;
}

.close-portfolio--content {
  right: 50%;
  -webkit-transform: translateX(50%);
  -ms-transform: translateX(50%);
  transform: translateX(50%);
}

.close-portfolio--content .close-portfolio-label {
  right: 50% !important;
  -webkit-transform: translateX(50%);
  -ms-transform: translateX(50%);
  transform: translateX(50%);
  top: 50px;
}

.detail-v1 .detail-label {
  display: block;
  font-size: 11px;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: .1rem;
  color: rgba(255, 255, 255, 0.5);
}

.detail-v1 .detail-val {
  font-size: 16px;
  color: #fff;
}

.detail-v1 .detail-val a {
  color: #fff;
}

.unslate_co--footer {
  text-align: center;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.5);
}

.unslate_co--footer a {
  color: rgba(255, 255, 255, 0.6);
}

.unslate_co--footer a:hover {
  color: #fff;
}

.unslate_co--footer .footer-site-logo {
  margin-bottom: 30px;
}

.unslate_co--footer .footer-site-logo a {
  font-size: 30px;
  font-weight: 900;
  font-family: "Raleway", sans-serif;
  color: #fff;
}

.unslate_co--footer .footer-site-logo a span {
  color: #fff;
}

.unslate_co--footer .footer-site-social {
  padding: 0;
  margin: 0;
  margin-bottom: 30px;
}

.unslate_co--footer .footer-site-social li {
  padding: 0;
  margin: 0;
  list-style: none;
  display: inline-block;
}

@media (max-width: 991.98px) {
  .unslate_co--footer .footer-site-social li {
    display: block;
  }
}

.unslate_co--footer .footer-site-social li a {
  display: inline-block;
  padding-left: 20px;
  padding-right: 20px;
  color: rgba(255, 255, 255, 0.5);
  letter-spacing: .1rem;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
}

.unslate_co--footer .footer-site-social li a:hover, .unslate_co--footer .footer-site-social li a:focus {
  color: #fff;
}

.loader-portfolio-wrap {
  text-align: center;
  display: none;
  opacity: 0;
  visibility: hidden;
  left: 50%;
  top: 0px;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  position: absolute;
}

.loader-portfolio, .site-loader {
  width: 2rem;
  height: 2rem;
  margin: 2rem auto;
  border-radius: 50%;
  border: 0.3rem solid rgba(255, 255, 255, 0.3);
  border-top-color: #fff;
  -webkit-animation: 1.5s spin infinite linear;
  animation: 1.5s spin infinite linear;
}

.loader-portfolio.dark, .site-loader.dark {
  border: 0.3rem solid rgba(0, 0, 0, 0.3);
  border-top-color: #000;
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.jarallax {
  position: relative;
  z-index: 0;
}

.jarallax > .jarallax-img {
  position: absolute;
  -o-object-fit: cover;
  object-fit: cover;
  /* support for plugin https://github.com/bfred-it/object-fit-images */
  font-family: 'object-fit: cover;';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

span.error {
  color: rgba(255, 255, 255, 0.5);
}

#message {
  resize: vertical;
}

#form-message-warning, #form-message-success {
  display: none;
}

#form-message-warning {
  color: #fff;
}

#form-message-success {
  color: #8cba51;
  font-size: 18px;
  font-weight: bold;
}

.submitting {
  float: left;
  width: 100%;
  padding: 10px 0;
  display: none;
  font-weight: bold;
  font-size: 13px;
  letter-spacing: .1rem;
  color: rgba(255, 255, 255, 0.5);
}

.unslate_co--sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 80px;
}

.unslate_co--sticky:after, .unslate_co--sticky:before {
  content: '';
  display: table;
}

.comment-form-wrap {
  clear: both;
}

.comment-list {
  padding: 0;
  margin: 0;
}

.comment-list .children {
  padding: 50px 0 0 40px;
  margin: 0;
  float: left;
  width: 100%;
}

.comment-list li {
  padding: 0;
  margin: 0 0 30px 0;
  float: left;
  width: 100%;
  clear: both;
  list-style: none;
}

.comment-list li .vcard {
  width: 80px;
  float: left;
}

.comment-list li .vcard img {
  width: 50px;
  border-radius: 50%;
}

.comment-list li .comment-body {
  float: right;
  width: calc(100% - 80px);
}

.comment-list li .comment-body h3 {
  font-size: 20px;
}

.comment-list li .comment-body .meta {
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: .1em;
  color: #ccc;
  margin-bottom: 20px;
}

.comment-list li .comment-body .reply {
  padding: 7px 15px;
  background: gray;
  color: #fff;
  text-transform: uppercase;
  border-radius: 30px;
  font-size: 11px;
  font-weight: 900;
  letter-spacing: .1rem;
}

.comment-list li .comment-body .reply:hover {
  color: #fff;
  background: #8c8c8c;
}

.categories_tags {
  font-weight: bold;
}

.post-single-navigation a {
  line-height: 1.5;
  font-weight: bold;
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: inline-block;
}

.post-single-navigation a span {
  margin-bottom: 10px;
  font-size: 11px;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.4);
}

.lines-wrap {
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

@media (max-width: 767.98px) {
  .lines-wrap {
    display: none;
  }
}

.lines-wrap .lines-inner {
  position: absolute;
  width: 1140px;
  left: 50%;
  margin-left: -570px;
  top: 0;
  height: 100%;
  border-left: 1px solid #222;
  border-right: 1px solid #222;
}

.lines-wrap .lines-inner .lines {
  position: absolute;
  left: 33.333333%;
  right: 33.333333%;
  height: 100%;
  border-left: 1px solid #222;
  border-right: 1px solid #222;
}

.gsap-reveal {
  position: relative;
  display: inline-block;
  overflow: hidden;
}

@media (max-width: 991.98px) {
  .gsap-reveal.gsap-reveal-filter {
    overflow: visible !important;
  }
}

.gsap-reveal .cover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: black;
}

.gsap-reveal-hero {
  line-height: 0;
}

.gsap-reveal-hero .reveal-wrap {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.gsap-reveal-hero .reveal-wrap .cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  margin-left: -100%;
  z-index: 2;
}

.gsap-reveal-hero .reveal-wrap .reveal-content {
  line-height: 1.5;
  z-index: 1;
  display: inline-block;
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
}

.gsap-reveal-img {
  line-height: 0;
}

.gsap-reveal-img .reveal-wrap {
  position: relative;
  overflow: hidden;
}

.gsap-reveal-img .reveal-wrap .cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  margin-left: -100%;
  z-index: 9;
}

.gsap-reveal-img .reveal-wrap .reveal-content {
  opacity: 0;
  visibility: hidden;
}

.gsap-reveal-img .reveal-wrap .reveal-content .portfolio-item-content h3 {
  margin-bottom: 10px;
}

.Navbar {
  height: 60px;
  position: fixed;
  display: flex;
  z-index: 999;
  width: 100%;
  justify-content: center;
  align-items: center;
  /* background: #191919; */
}

.Navbar > .nav-logo {
  position: absolute;
  color: #ffffff;
}

.Navbar > .nav-items > a {
  color: #ffffff;
  font-size: 16px;
  text-decoration: none;
  margin: 15px;
  position: relative;
  opacity: 0.9;
}

.Navbar > .nav-items > a:hover {
  opacity: 1;
}

.Navbar > .nav-items > a::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 2px;
  background: #ffffff;
  transition: all 0.45s;
}

.Navbar > .nav-items > a:hover::before {
  width: 100%;
}

.Navbar > .nav-toggle {
  display: none;
}

@media (max-width: 700px) {
  .Navbar {
      justify-content: space-between;
     
    }
    .Navbar > .nav-logo {
      position: relative;
    }
    
  .Navbar > .nav-items {
    position: absolute;
    top: 60px;
    display: flex;
    flex-direction: column;
    background: #191919;
    left: 0;
    width: 100%;
    height: 70vh;
    transform: translateX(-100%);
    transition: all .45s;
  }

  .Navbar > .nav-items > a::before {
      background: transparent;
  }

  .Navbar > .nav-items.open {
      transform: translateX(0);
  }

  .Navbar > .nav-toggle {
    display: flex;
    width: 50px;
    height: 50px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .nav-toggle > .bar {
    position: relative;
    width: 32px;
    height: 2px;
    background: #ffffff;
    transition: all 0.45s ease-in-out;
  }

  .nav-toggle > .bar::before,
  .nav-toggle > .bar::after {
    content: "";
    position: absolute;
    height: 2px;
    background: #ffffff;
    border-radius: 2px;
    transition: all 0.45s ease-in-out;
  }

  .nav-toggle > .bar::before {
    width: 25px;
    transform: translateY(-8px);
    right: 0;
  }

  .nav-toggle > .bar::after {
    width: 32px;
    transform: translateY(8px);
  }

  .nav-toggle.open > .bar {
      transform: translateX(-40px);
      background: transparent;
  }

  .nav-toggle.open > .bar::before {
      width: 32px;
      transform: rotate(45deg) translate(26px, -26px);
  }

  .nav-toggle.open > .bar::after {
    transform: rotate(-45deg) translate(26px, 26px);
  }


}
